.pad-cell {
	padding: $panelSpacing;
}

.pad-cell-left {
	padding-left: $panelSpacing;
}

.pad-cell-right {
	padding-right: $panelSpacing;
}

.pad-cell-top {
	margin-top: $panelSpacing;
}

.pad-cell-bottom {
	margin-bottom: $panelSpacing;
}

.admin-panel-container {
	position: relative;
	padding: $panelSpacing;
	z-index: 1;
}

.breadcrumb {
	margin: -10px 0 0 0;
	font-size: 0.95em;
}

.breadcrumb a {
	text-transform: uppercase;
}

.header-actions {
	padding-top: 8px;
}

.header-actions .button, 
.header-actions .primary-button,
.header-actions .attention-button,
.header-actions .drop-down {
	vertical-align: middle;
}

.header-actions .divider {
	color: #555555;
}

.panel-tertiary-controls a {
	display: inline-block;
	margin: 0 0 0 20px;
	text-transform: uppercase;
}

.panel-cell {
	padding: $panelPadding;
	margin: $panelSpacing 0 0 0;
	background: #222222;
}

.locked-message {
	text-align: center;
}

.status-indicatator {
	background: $statusInactiveColor;
}

.status-indicatator.inactive {
	background: $statusInactiveColor;
}

.status-indicatator.active {
	background: $statusActiveColor;
}

.status-indicatator.published {
	background: $statusPublishedColor;
}

.status-indicatator.changed {
	background: $statusChangedColor;
}

.status-indicatator.locked {
	background: $statusLockedColor;
}

.status-indicatator.complete {
	background: $statusCompleteColor;
}

.code-editor {
	@extend .monospace;
	margin: 0;
	padding: 5px 10px;
	width: 100%;
	overflow: auto;
	font-size: 0.9em;
	color: #ffffff;
	border: 1px solid #555555;
	outline: none;
	background: #232323;
}

a.help-button {
	padding: 20px;
	width: 40%;
	color: #ececec;
	border: 1px solid white;
	border-radius: 10px;
	text-align: center;
	vertical-align: middle;
}

a.help-button:hover {
	color: $linkColor;
}

// ------------------------------- ON BOARDING -------------------------------

.onboarding-modal {

	// height: 90vh;
	//overflow-y: auto;

	h2 {
		margin: 0 0 20px 0;
		text-transform: initial;
		font-size: 2.0rem;
	}

	.steps {
		padding: 0;
		display: flex;
		justify-content: space-evenly;
		align-content: stretch;
		background: #666666;
		
		overflow: hidden;
	}

	.steps li {
		display: flex;
		position: relative;
		margin: 0;
		padding: 15px;
		flex: 1;
		list-style: none;
	}

	.steps li:not(:first-child):before {
		content: '';
		position: absolute;
		bottom: 0;
		left: -22px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 60px 25px;
		border-color: transparent transparent #666666 transparent;
	}

	.steps li:not(:last-child):after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 60px 25px;
		border-color: transparent transparent #222222 transparent;
	}

	.steps li.complete {
		background-color: #666666;
	}

	.steps li.complete:not(:last-child):after {
		border-color: transparent transparent #222222 transparent;
	}

	.steps li.complete button, .steps li.complete span {
		text-decoration: line-through;
		color: #aaaaaa;
	}

	.steps li.current {
		background: #ffffff;
	}

	.steps li.current:not(:first-child):before {
		border-color: transparent transparent #ffffff transparent;
	}

	.steps li.current button, .steps li.current span {
		color: #1b1b1b;
		font-weight: $boldFontWeight;
	}

	.steps li button, .steps li span {
		padding: 2px 0 0 5px;
		color: #efefef;
	}

	.steps li button {
		cursor: pointer;
	}

	p {
		margin: 30px 0;
	}

	.field {
		margin: 0 0 20px 0;
	}

	.field label {
		color: #ffffff;
	}

	.field p {
		margin: 5px 0;
	}

	.controls {
		padding: 20px 0 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.w-tc-editor-text, .w-tc-editor-preview {
		white-space: pre !important;
	}

	.copy-button {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}

	.copy-button:hover svg {
		fill: $linkColor;
	}

}

// ------------------------------- DASHBOARD ---------------------------------

.dashboard-task-icon {
	padding: 13px;
	color: #ffffff;
	text-align: center;
	text-transform: uppercase;
}

// ------------------------------- CONTENT ---------------------------------

.content-editor {
	margin: $panelSpacing 0 0 0;
	padding: 1px;
}

.content-editor iframe {
	width: 100%;
	height: 100%;
	border: none;
}

.content-editor iframe body {
	
}

#custom-properties-panel {
	padding-bottom: 5px;
}

.properties-panel-cell {
	display: table;
	width: 100%;
	padding: 0 0 $panelSpacing;
}

.properties-panel-cell input[type=text] {
	display: table-cell;
	width: 100%;
	vertical-align: top;
}

.properties-panel-cell .remove-button {
	display: table-cell;
	margin: 0;
	padding: 0 15px;
	vertical-align: top;
	text-align: center;
	font-size: 1.5em;
	color: $linkColor;
	cursor: pointer;
}

.properties-panel-cell .remove-button:hover {
	color: $linkHoverColor;
}

.results-count {
	display: inline-block;
	margin: 7px 0 0 0;
	vertical-align: top;
}

// -------------------------- CHANNELS --------------------------------

.view-channel .selected-object {
	margin: 10px 0 0 0;
}

.view-channel .selected-object h2 {
	margin: 10px 0 0 0;
	padding: 0;
	color: #cecece;
	font-size: 1.3em;
}

.primary-fields {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
	gap: 13px;
}

.primary-fields .status-indicatator {
	position: absolute;
	left: -18px;
	top: -18px;
	margin: 0 4px 0 0;
	width: 2px;
	height: 93px;
}

.primary-fields .read-only {
	display: inline-block;
	padding: 8px 0;
}

.panel-cell.predictive-attributes {
	padding-right: 5px;
	padding-bottom: 5px;
}

.predictive-attribute-container {
	display: inline-block;
}

.predictive-attributes-grid {
	display: block;
}

.predictive-attribute-icon {
	position: relative;
	width: 125px;
	height: 125px;
	color: #ffffff;
	text-align: center;
	text-transform: uppercase;
	vertical-align: middle;
	background: linear-gradient( #595959, #292929 );
	cursor: pointer;

	.label {
		padding: 0 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.predictive-attribute-icon .remove-button {
	display: none;
	position: absolute;
	top: -6px;
	left: -6px;
}

.predictive-attribute-icon .remove-button svg path {
	fill: $linkColor;
}

.predictive-attribute-icon .remove-button:hover svg path {
	fill: #ffffff;
}

.new-predictive-attribute-button {
	width: 125px;
	height: 125px;
	color: $linkColor;
	text-align: center;
	text-transform: uppercase;
	vertical-align: middle;
	cursor: pointer;
}

.new-predictive-attribute-button .icon {
	padding: 10px 0 0 0;
}

.predictive-attribute-icon .icon, .new-predictive-attribute-button .icon {
	display: inline-block;
	margin: 25px 0 15px;
	width: 48px;
	height: 48px;
	line-height: 30px;
	font-size: 70px;
}

.new-predictive-attribute-button:hover {
	color: #ffffff;
}

.predictive-attributes.disabled .predictive-attribute-icon, 
.predictive-attributes.disabled .new-predictive-attribute-button {
	cursor: default;
	opacity: 0.35;
}

.predictive-attributes.disabled .predictive-attribute-icon .remove-button {
	visibility: hidden;
}

.predictive-attributes.disabled .new-predictive-attribute-button:hover {
	color: $linkColor;
}

.performance-metrics {

	h3 {
		margin-bottom: 18px;
	}

	.info-panel {
		flex-basis: 0;
		flex-grow: 1;
	}

	.info-block {
		margin: 0 0 10px 0;
	}

	.info-block-sml {
		margin: 0 0 15px 0;
	}

	.info-stat {
		display: inline-block;
		margin: -20px 8px -20px 0;
		font-size: 5.5vw;
		font-weight: $boldFontWeight;
		letter-spacing: -3px;
	}

	.info-stat-label {
		display: inline-block;
		padding: 0 0 0 5px;
		font-size: 1.5vw;
	}

	.info-detail {
		
		display: inline-block;
		margin: 0 8px -20px 0;
		font-size: 4.5vw;
		font-weight: $boldFontWeight;
		letter-spacing: -3px;
	}

	.info-detail-sml {
		font-weight: $boldFontWeight;
		display: inline-block;
		margin: 5px 0 -10px;
		font-size: 3.2vw;
	}

	p { 
		margin: 5px 0 0;
	}

	.loader {
		display: flex;
		align-items: center;
		justify-content: center;
	}

}

// -------------------------- ------------------- --------------------------------

.published-header {
	color: $statusPublishedColor;
}

.published-complete {
	color: $statusCompleteColor;
}

// -------------------------- Optimization Editor --------------------------------

.optimization-editor {

	.tab-component-content-container {
		padding: 0 0 0;

		.panel-cell:first-of-type {
			margin: 0;
		}

		h3 {
			margin: 10px 0 0;
		}

	}

	.url-rules {

		padding: 0 18px;

		.rule {
			display: flex;
			margin: 0 0 13px;
		}

	}

}

// -------------------------- Audiences Editor --------------------------------

.audience-editor {

	padding-bottom: 50px;

	.rule-group {
		margin-bottom: 20px;
	}

	.rule-group:not(:first-of-type)::before {
		display: block;
		content: '';
		margin: -1px -19px 0;
		border-top: solid 80px #1B1B1B;
	}

	.audience-rule {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: $panelSpacing * 3 0;
		border-bottom: 1px solid #333333;
	}

	.audience-rule:first-of-type {
		padding-top: $panelSpacing * 2;
	}

	.audience-rule:not(:first-child) .combinator {
		margin-top: -67px;

		.drop-down-button {
			background-color: #222222 !important;
		}
	}

	.audience-rule:first-child .combinator {
		margin-top: -120px;
	}

}

// -------------------------- On Site Editor --------------------------------

.hover-highlight {
	position: absolute;
	left: -10px;
	top: 0;
	z-index: 5;
	width: 0;
	height: 0;
	pointer-events: none;
	border: 1px dotted rgb(115, 157, 255);
	outline: 1px dotted rgb(0, 64, 255);
	outline-offset: 0px;
	// background-color: rgba( 255, 0, 0, 0.15 );
}

.selected-highlight {
	position: absolute;
	left: -10px;
	top: 0;
	z-index: 5;
	width: 0;
	height: 0;
	pointer-events: none;
	// border: 2px solid #1edffd;
	// border: 1px dotted rgb(255, 110, 137);
	// outline: 1px dotted rgb(244, 3, 63);
	border: 1px dotted rgb(115, 157, 255);
	outline: 1px dotted rgb(0, 64, 255);
	// background-color: rgba( 255, 0, 0, 0.15 );
}

.experience-editor-controls {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;

	.control-button {
		padding: 3px 0 0 0;
		width: 34px;
		min-width: 34px;
		height: 34px;
		text-align: center;
		background: #2e2e2e; //linear-gradient( #595959, #292929 );
		cursor: pointer;
	}

	.control-button:hover svg g, 
	.control-button:hover svg,
	.control-button:hover polyline,
	.control-button.selected svg g, 
	.control-button.selected svg {
		fill: $linkColor;
		stroke: $linkColor;
	}

	.control-button:hover polygon,
	.control-button.selected polygon  {
		stroke: $linkColor;
	}

	.control-button:hover svg path, .control-button.selected svg path {
		stroke: $linkColor;
	}

	.control-button.disabled {
		opacity: 0.2;
		pointer-events: none;
	}

	#selected-element-path {
		display: flex;
		flex-grow: 1;
		align-items: center;
		padding: 0 0 0 20px;
		overflow: hidden;
		font-size: 0.8em;
	}

	#selected-element-path .input {
		justify-content: flex-end;
		  display: inline-flex;
		margin: 0;
		padding: 8px 10px;
		width: 100%;
		outline: none;
		text-align: right;
		white-space: nowrap;
		overflow: hidden;
	}

	#selected-element-path .input:focus {
		border: 1px solid #555555;
		background: linear-gradient( #232323, #3c3c3c );
	}

	#selected-element-path button {
		margin: 0 0 0 8px;
		cursor: pointer;
	}
	
	#selected-element-path button:hover svg {
		fill: $linkColor;
		stroke: $linkColor;
	}

}

// -------------------------- Experience Editor --------------------------------

.experience-editor {

	.experience-text-editor {

		textarea {
			padding: 15px;
			overflow-y: auto;
			width: 100%;
			font-size: 12px;
			font-family: ui-monospace, SFMono-Regular, "SF Mono", Consolas, "Liberation Mono", Menlo, monospace;
			color: #c9d1d9;
			border: none;
			background-color: #1b1b1b;
			resize: none;
		}

		textarea:focus {
			outline: none;
		}

	}

	.experience-styles-editor {

		background-color: #222222;

		.property-controls {
			display: flex;
			// flex-direction: column;
			flex-wrap: wrap;
			align-items: stretch;
			align-content: flex-start;
			gap: 5px;
			column-count: 3;
			overflow-y: auto;
		}

		.property-control {
			display: flex;
			padding: 10px;
			gap: 5;
			flex-basis: 24.5%;
			align-items: center;
			font-size: 0.8em;
			background-color: #292929;
		}

		.property-controls.disabled .property-control {
			color: $disabledTextColor;
			background-color: #232323;
		}

		.property-control .style-property-picker {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			cursor: pointer;
			color: $linkHoverColor;
			font-size: 1.1em;
		}

		.property-control .style-property-picker:hover {
			color: $linkColor;
		}


		.property-control .style-property-picker span {
			padding: 0 6px 0 0;
		}

		.property-control .remove-button {
			margin: 5px 0 0 10px;
			cursor: pointer;
		}

		.property-control button svg path {
			fill: $linkHoverColor;
		}

		.property-control button:hover svg path {
			fill: $linkColor;
		}

		.property-control label {
			margin: 0;
		}

		.property-control input {
			background: none;
			border: none;
		}

		.property-control input:focus, .property-control input.no-value {
			border: 1px solid #555555;
			background: linear-gradient( #232323, #3c3c3c );
		}

		
	}

	.experience-code-editor.invalid {
		border: 1px solid rgb(137, 0, 0)
	}

	.modifications-list {

		.modification-item {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 13px;
			padding: 10px;
			margin-bottom: 13px;
			background-color: #2e2e2e;
			border: 1px solid transparent;
			overflow: hidden;
		}

		.modification-item.highlighted {
			border: 1px solid #555555;
		}

		.modification-path {
			flex-basis: 100%;
			font-size: 0.85em;
			cursor: default;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.control-button {
			cursor: pointer;
		}

		.control-button svg path{
			fill: $linkHoverColor;
		}

		.control-button:hover svg path {
			fill: $linkColor;
		}

		.control-button.view {
			margin: 5px 0 0 0;
		}
		
		.control-button.remove {
			margin: 9px 0 0 0;
		}

	}

}

// TODO: move to ColorPicker.scss
.color-picker {

	.color-chip {
		width: 20px;
		height: 12px;
		border: 1px solid #555555;
		cursor: pointer;
	}

	.picker-container {
		position: absolute;
		z-index: 10;
		right: 55px;
		width: 220px;
	}

	.sketch-picker {
		background: #222222 !important;
		font: $fontBaseWeight 0.75em $fontFace !important;
	}
	
	.sketch-picker input {
		margin: 0 0 2px 0 !important;
		padding: 2px 3px !important;
		width: 100% !important;
		font: $fontBaseWeight 1em $fontFace !important;
		color: #ffffff !important;
		border: 1px solid #555555 !important;
		outline: none !important;
		background: linear-gradient( #232323, #3c3c3c ) !important;
		box-shadow: none !important;
	}
	
	.sketch-picker label {
		color: $textColor !important;
	}
	
	.sketch-picker .flexbox-fix {
		border-top-color: #555555 !important;
	}
	
}

// -------------------------- Optimization Analytics --------------------------------

.optimization-analytics {

	.details-row {
		display: flex;
		align-items: stretch;
		gap: 13;
		margin: 3px 0;
		padding: 10px;
	}

	.details-header {
		background: #222222;
	}

	.details-detail {
		background: #1b1b1b;
	}


}