.data-grid {
	background: #1B1B1B;
	padding: 0 0 2px 0;
}

.data-grid .grid {
	height: 42px;
	padding: 12px 10px;
	overflow: hidden;
}

.data-grid-header {
	color: #888888;
	background: linear-gradient( #2c2c2c, #1f1f1f );
}

.data-grid-body {
	overflow-y: auto;
}

.data-grid-body-row {
	margin: 2px 0 0 0;
	color: #ffffff;
	background: #222222;
	cursor: default;
}

.data-grid-body-row:hover {
	//color: $linkColor;
	background: #2e2e2e;
}

.data-grid .check-column {
	margin-top: -2px;
	padding-left: 5px;
}

.data-grid .edit-column {
	padding-top: 2px;
}

.data-grid .edit-column svg {
	cursor: pointer;
}

.data-grid .edit-column svg:hover {
	opacity: 0.7;
}

.data-grid .status-indicatator {
	position: absolute;
	left: 0px;
	top: 0px;
	margin: 0 4px 0 0;
	width: 2px;
	height: 42px;
}

.data-grid .no-content {
	width: 100%;
	padding: 50px 0;
	text-align: center;
	background: #222222;
}

// Disabled States

.data-grid.disabled .data-grid-body-row {
	color: $disabledLinkColor;
}

.data-grid.disabled .data-grid-body-row:hover {
	background: #222222;
}

.data-grid.disabled .status-indicatator {
	opacity: 0.2;
}

.data-grid .grid-cell {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}