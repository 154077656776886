.primary-nav {
	position: relative;
	width: 20%;
	text-transform: uppercase;
	user-select: none;
	z-index: 2;
	transition: width 200ms ease-out;
}

.primary-nav .shadow-layer {
	position: absolute;
	top: 0;
	z-index: 1;
	width: 100%;
	height: 0;
	background: #222222;
	box-shadow: 0 0 7px 1px #101010;
}

.primary-nav nav {
	position: relative;
	top: 0;
	z-index: 2;
	width: 100%;
}

.primary-nav-item {
	display: block;
	padding: 19px 10px 20px 10px;
	font-weight: 400;
	letter-spacing: -0.005em;
	color: #ffffff;
	background: linear-gradient( #2a2a2a, #1c1c1c );
	cursor: pointer;
}

.primary-sub-nav {
	display: none;
	padding: 8px 0;
	overflow: hidden;
}

.primary-sub-nav a {
	display: block;
	padding: 8px 0 8px 30px;
	white-space: nowrap;
	color: #a6a6a6;
	cursor: pointer;
}

.primary-sub-nav a.selected .label {
	color: #ffffff;
	cursor: default;
}

.primary-nav-item .icon {
	display: none; 
	width: 100%;
	margin: -10px 0 0 0;
	padding: 0.5px 0 0;
	text-align: center;
}

.primary-nav-item svg {
	position: relative;
	top: 7px;
}

.primary-nav-item:hover .icon svg path {
	fill: inherit;
	color: inherit;
}

.primary-nav .controls {
	position: absolute;
	top: 50vh;
	// left: 100%;
	right: -6px;
	width: 15px;
	height: 30px;
	z-index: 2;
	background: #2e2e2e;
}

.primary-nav .controls button {
	position: absolute;
	top: 5px;
	cursor: pointer;
}

.primary-nav.minimized .controls .open, .primary-nav .controls .close {
	display: block;
}

.primary-nav .controls .open, .primary-nav.minimized .controls .close {
	display: none;
}


.primary-nav .controls svg {
	position: absolute;
	top: -1px;
	left: -5px;
}

// Minimized

.primary-nav.minimized {
	width: 60px;
	min-width: 60px;
}

.primary-nav.minimized .primary-nav-item .label {
	display: none;
}

.primary-nav.minimized .primary-nav-item .icon {
	display: block;
}