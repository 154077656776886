.content-accordion {

	.accordion-header {
		display: block;
		padding: 5px 10px;
		width: 100%;
		text-align: left;
		color: $linkWhite;
		background: #292929;
		// background: linear-gradient( #444444, #333333 );
		// background: linear-gradient( #2c2c2c, #1f1f1f );
	}

	.accordion-content {
		display: none;
		padding: 18px;
		background: #1e1e1e;
	}

	.accordion-content.selected {
		display: block;
	}

}