$maxWidth: 1200px;
$tabletWidth: 768px;
$mobileWidth: 480px;

$backgroundGray: #1B1B1B;
$textColor: #bcbcbc;
$disabledTextColor: #666666;
$accentColor: #7ef2fa;
$linkColor: #1EDFFD;
$linkHoverColor: #ffffff;
$disabledLinkColor: #666666;
$linkWhite: #ffffff;

$errorColor:#f13e3e;
$warnColor: #eba100;

$statusInactiveColor: #474747;
$statusPublishedColor: #d874d0 ;
$statusActiveColor: #09b1ff;
$statusChangedColor: rgb(253, 184, 81);
$statusChangedColorDim: rgb(127, 92, 41);
// $statusLockedColor: #f13e3e; -- locked for editing by another user
$statusLockedColor: #e538e8; // -- locked because it's in use by a published object
$statusCompleteColor: #09FFA9;

$grid: 0 5 8 10 15 20 23 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100 (33 33.333%) (66 66.666%);
$tabletGrid: $grid;
$mobileGrid: 0 10 25 50 75 90 100;

$panelPadding: 18px;
$panelSpacing: 13px;

// $fontFace: 'Roboto', sans-serif;
$fontFace: 'Roboto Condensed', sans-serif;
// $fontFace: 'Poppins', sans-serif;
// $fontFace: 'Ubuntu Sans', sans-serif;
// $fontFace: 'Lato', sans-serif;
// $fontFace: 'Nunito Sans', sans-serif;
// $fontFace: 'Reddit Sans', sans-serif;
$fontBaseSize: 0.98em; //0.98em;
$fontBaseWeight: 400;
$boldFontWeight: 600;

@import "../src/sphere/components/Grid.scss";
@import "../src/sphere/admin/screens/SignIn.scss";
@import "../src/sphere/admin/screens/Admin.scss";
@import "../src/sphere/admin/screens/IFrame.scss";
@import "../src/sphere/admin/components/Header.scss";
@import "../src/sphere/admin/components/PrimaryNavigation.scss";
@import "../src/sphere/admin/components/controls/DataGrid.scss";
@import "../src/sphere/admin/components/controls/Accordion.scss";
@import "../src/sphere/admin/components/controls/ContentAccordion.scss";
@import "../src/sphere/admin/components/controls/TabComponent.scss";
@import "../src/sphere/admin/components/modals/Modal.scss";
@import "../src/sphere/admin/components/UpSellComponent.scss";
@import "../src/sphere/admin/panels/AdminPanel.scss";

@include grid( default, $grid );
@include responsive-grid( tablet, $tabletGrid, $tabletWidth );
@include responsive-grid( mobile, $mobileGrid, $mobileWidth );

* {
	box-sizing: border-box;
}

html, body {
	width: 100%;
}

body {
	margin: 0;
	padding: 0;
	font: $fontBaseWeight $fontBaseSize $fontFace;
	// letter-spacing: 0.01em;
	color: $textColor;
	background: $backgroundGray;
}

h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
	font-weight: $boldFontWeight;
	text-transform: uppercase;
	cursor: default;
}

h2 {
	margin: -4px 0 10px 0;
	font-size: 1.7em;
	color: #ffffff;
}

h3 {
	margin: -2px 0 10px 0;
	font-size: 1.1em;
	color: #ffffff;
}

a, .link-button {
	// font: 300 0.9em 'Oswald', sans-serif;
	// font: 400 1em "Roboto", sans-serif;
	text-decoration: none;
	color: $linkColor;
	cursor: pointer;
	background: none;
	border: none;

	-webkit-transition: color 0.3s ease-in-out;
	-moz-transition: color 0.3s ease-in-out;
	-o-transition: color 0.3s ease-in-out;
	transition: color 0.3s ease-in-out;

	svg {

		path {
			-webkit-transition: fill 0.3s ease-in-out;
			-moz-transition: fill 0.3s ease-in-out;
			-o-transition: fill 0.3s ease-in-out;
			transition: fill 0.3s ease-in-out;
		}
	}
}

a:hover, .link-button:hover {
	color: $linkHoverColor;

	svg path {
		fill: $linkHoverColor;
	}
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.monospace {
	font-family: 'Courier New', Courier, monospace;
}

iframe {
	border: none;
}

// ---------------------------- Lists ------------------------------- //

ul {
	padding-left: 20px;
}

// ---------------------------- Form Fields ------------------------------- //

label {
	display: block;
	margin: 0 0 5px 0;
	// text-transform: uppercase;
}

.hint {
	display: inline;
	top: -8px;
	position: relative;
	width: 12px;
	height: 12px;
	text-align: left;
	cursor: default;
}

h3 .hint {
	font-size: 0.9em;
	// font-weight: 300;
}

.hint span {
	display: inline-block;
	padding: 3px 0 0 0;
	width: 16px;
	height: 16px;
	line-height: 1em;
	font-size: 0.75em;
	font-weight: $boldFontWeight;
	text-align: center;
	color: #ffffff;
	border-radius: 50%;
	background: #444444;
}

.hint .tool-tip {
	display: block;
	position: fixed;
	z-index: 100;
	padding: 10px;
	text-transform: none;
	letter-spacing: 0.02em;
	font-weight: 300;
	color: #cecece;
	border: 1px solid #5c5c5c;
	background: #1b1b1b;
	box-shadow: 0 2px 5px 0 #000000;
}

.hint .tool-tip span {
	color: #efefef;
}

input[type=text], input[type=password], input[type=email] {
	margin: 0;
	padding: 7px 10px 8px 10px;
	width: 100%;
	font: $fontBaseWeight $fontBaseSize $fontFace;
	color: #ffffff;
	border: 1px solid #555555;
	outline: none;
	background: linear-gradient( #232323, #3c3c3c );
}

input[type=text].invalid, input[type=password].invalid, input[type=email].invalid,
input[type=text].has-lost-focus:invalid, input[type=password].has-lost-focus:invalid, input[type=email].has-lost-focus:invalid {
	border-color: rgb(228, 31, 80);
}

input[type=text]:disabled, input[type=password]:disabled, input[type=email]:disabled {
	cursor: default;
	color: #cccccc;
	border: 1px solid #333333;
}

input[type=text]:read-only, input[type=password]:read-only, input[type=email]:read-only {
	cursor: default;
	border: 1px solid #333333;
}

input::placeholder {
	opacity: 0.5;
}

.checkbox, .radio {
	label {
		display: inline-block;
		position: relative;
		top: -5px;
		padding: 0 0 0 8px;
	}
}

.radio-group .radio {
	display: inline-block;
	margin: 0 20px 0 0;
}

input[type=checkbox], input[type=radio] {
	display: inline-block;
	width: 16px;
	height: 16px;
	border: 1px solid #555555;
	appearance: none;
	outline: none;
	background: linear-gradient( #232323, #3c3c3c );
	cursor: pointer;
}

input[type=checkbox]:checked {
	background: #a6a6a6;
}

input[type=checkbox]:disabled {
	opacity: 0.35;
	cursor: default;
}

input[type=radio] {
	border-radius: 50%;
}

input[type=radio]:checked {
	background: #a6a6a6;
}

input.slim {
	padding: 1px 5px;
	// font: 300 0.9em 'Oswald', sans-serif;
}

// Buttons

button {
	margin: 0;
	padding: 0;
	font: $fontBaseWeight $fontBaseSize $fontFace;
	background: none;
	border: none;
	cursor: pointer;
}

.button {
	display: inline-block;
	padding: 9px 10px;
	text-align: center;
	text-transform: uppercase;
	font-weight: $boldFontWeight;
	overflow: hidden;
	color: $linkColor;
	background: linear-gradient( #595959, #292929 );
	cursor: pointer;
}

.icon-button {
	display: inline-block;
	padding: 5px 0 0 0;
	width: 34px;
	height: 34px;
	text-align: center;
	background: linear-gradient( #595959, #292929 );
	cursor: pointer;
}

.button:hover {
	background: linear-gradient( #666666, #333333 );
}

.button.disabled, .icon-button.disabled  {
	opacity: 0.35;
	cursor: default;
}

.button.disabled:hover {
	background: linear-gradient( #595959, #292929 );
}

.button.disabled:hover svg path, .icon-button.disabled:hover svg path {
	fill: $linkColor;
}

.button.slim {
	padding: 2px 6px;
	font-size: 0.97em;
}

// Primary Button

.primary-button {
	display: inline-block;
	padding: 9px 10px;
	text-align: center;
	text-transform: uppercase;
	font-weight: $boldFontWeight;
	overflow: hidden;
	color: #ffffff;
	background: linear-gradient( #3B9AA8, #19373B );
	cursor: pointer;
}

.primary-button:hover {
	background: linear-gradient( rgb(68, 170, 185), rgb(33, 70, 75) );
}

.primary-button.disabled, .primary-button.disabled:hover {
	opacity: 0.35;
	background: linear-gradient( #388d9a, #162f32 );
	cursor: default;
}

// Attention Button

.attention-button {
	display: inline-block;
	padding: 9px 10px;
	text-align: center;
	text-transform: uppercase;
	font-weight: $boldFontWeight;
	overflow: hidden;
	color: #ffffff;
	background: linear-gradient(#cd79d8, #5b2e61);
	cursor: pointer;
}

.attention-button:hover {
	background: linear-gradient(#d681e1, #733d7a)
}

.attention-button.disabled, .attention-button.disabled:hover {
	opacity: 0.35;
	background: linear-gradient( #4fbc7e, #275e49 );
	cursor: default;
}

// Flat Button

.flat-button {
	display: inline-block;
	padding: 7px 10px;
	height: 36px;
	text-align: center;
	text-transform: uppercase;
	font-weight: $boldFontWeight;
	overflow: hidden;
	color: $linkColor;
	background: #414141;
	cursor: pointer;
}

//

.drop-down {
	// position: relative;
}

.drop-down-button {
	display: inline-block;
	position: relative;
	padding: 8px 10px;
	color: #efefef;
	background: linear-gradient( #595959, #292929 );
	cursor: pointer;
}

.drop-down-button span {
	// display: inline-block;
	width: 100%;
	padding: 0 25px 0 0;
	text-align: left;
	vertical-align: top;
	user-select: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.drop-down-button span:not(.item-selected) {
	color: #bcbcbc;
}

.drop-down-button span.empty {
	padding: 0 15px 0 0;
}

.drop-down-button .divider {
	position: absolute;
	top: 0;
	right: 34px;
	width: 1px;
	height: 100%;
	background: #1b1b1b;
}

.drop-down-button .icon {
	position: absolute;
	top: 6px;
	right: 5px;
}

.drop-down.padless {

	.drop-down-button {
		height: auto;
		padding: 0;
	}

	.drop-down-button .icon {
		top: 0;
		right: 0;
	}
}

.drop-down-button:hover {
	background: linear-gradient( #666666, #333333 );
}

.drop-down.disabled .drop-down-button {
	opacity: 0.55;
	cursor: default;
}

.drop-down.disabled .drop-down-button:hover {
	background: linear-gradient( #595959, #292929 );
}

.drop-down.disabled .drop-down-button span, .drop-down.disabled .icon-button {
	cursor: default;
}

.drop-down.disabled .drop-down-button:hover .icon-button svg path, .drop-down.disabled:hover span {
	fill: $linkColor;
}

.drop-down-items {
	display: none;
	position: fixed;
	z-index: 10;
	max-height: 250px;
	margin: 0;
	padding: 0;
    // box-sizing: content-box;
	overflow-x: hidden;
	overflow-y: auto;
	text-align: left;
	color: #efefef;
	background: linear-gradient( #454545, #353535 );
	box-shadow: 0 2px 5px 0 #000000;
}

.drop-down-items li {
	margin: 0;
	padding: 5px 10px;
	list-style: none;
	cursor: pointer;
}

.drop-down-items li:hover {
	background: #333333;
}

.control-pad-left {
	margin-left: 10px;
}

.control-pad-right {
	margin-right: 10px;
}

.control-pad-top {
	margin-top: 10px;
}

.control-pad-bottom {
	margin-bottom: 10px;
}

.error {
	color: $errorColor;
}

/* TODO: refactor naming */
.donut-chart {

	.donut {
		display: flex;
		position: relative;
		width: 100%;
		height: 0;
		padding-top: 50%;
		padding-bottom: 50%;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		/*
		background: conic-gradient(
			hsl(360, 100%, 50%) 0deg 20deg,
			hsl(315, 100%, 50%) 40deg 90deg,
			hsl(270, 100%, 50%),
			hsl(225, 100%, 50%),
			hsl(180, 100%, 50%),
			hsl(135, 100%, 50%),
			hsl(90, 100%, 50%),
			hsl(45, 100%, 50%),
			hsl(0, 100%, 50%)
		);
		*/
	}
	
	.hole {
		width: 70%;
		height: 0;
		padding: 35% 0 35% 0;
		border-radius: 50%;
		background: #222222;
	}

	.hole2 {
		width: 60%;
		height: 0;
		padding: 30% 0 30% 0;
		border-radius: 50%;
		background: #222222;
	}

	ul {
		margin: 0;
		padding: 0;
		// margin: 0 13px 0 0;
	}

	li {
		display: flex;
		list-style-type: none;
		// margin: 0 0 5px 0;
	}

	.name {
		flex-grow: 2; 
		margin: 0 5px 0 0;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.value {
		padding: 0 0 0 15px;
		// font-size: 1.1em;
		// font-weight: 700;
	}

}

.modal .preview-channel-dialog {
	padding: 0;
}

.folding {
	margin: 20px auto;
	width: 20px;
	height: 20px;
	position: relative;
	transform: rotateZ(45deg);
}
   
.folding .sk-cube {
	float: left;
	width: 50%;
	height: 50%;
	position: relative;
	transform: scale(1.1);
}
   
.folding .sk-cube:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #b5b5b5;
	
	animation: sk-foldCubeAngle 1.0s infinite linear both;
	transform-origin: 100% 100%;
}
   
.folding .sk-cube2 {
	transform: scale(1.1) rotateZ(90deg);
}
   
.folding .sk-cube3 {
	transform: scale(1.1) rotateZ(180deg);
}
   
.folding .sk-cube4 {
	transform: scale(1.1) rotateZ(270deg);
  }
   
.folding .sk-cube2:before {
	animation-delay: 0.1s;
}
   
.folding .sk-cube3:before {
	animation-delay: 0.2s;
}
   
.folding .sk-cube4:before {
	animation-delay: 0.3s;
}
   
@keyframes sk-foldCubeAngle {
	0%, 10% {
		transform: perspective(140px) rotateX(-180deg);
		opacity: 0.1;
	}
	25%, 75% {
		transform: perspective(140px) rotateX(0deg);
		opacity: 1;
	}
	90%, 100% {
		transform: perspective(140px) rotateY(180deg);
		opacity: 0.1;
	}
}

.VictoryContainer {
	svg {
		overflow: visible;
	}
}

.no-select {
	-webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none;
}

.resize-handle {
	position: relative;
	top: 7px;
	height: 3px;
	width: 100px;
	margin: 0 auto;
	padding: 0;
	border-width: 0;
	border-top: 1px solid #555555;
	border-bottom: 1px solid #555555;
	cursor: row-resize;
}

.vertical-resize-handle {
	position: relative;
	right: 7px;
	width: 3px;
	height: 100px;
	margin: 0 auto;
	padding: 0;
	border-width: 0;
	border-left: 1px solid #555555;
	border-right: 1px solid #555555;
	cursor: col-resize;
}

.resize-handle:active, .vertical-resize-handle:active {
	cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

//

.channel-analytics-detail {

	min-width: 100%;
	
	th, td {
		padding: 0.7em;
	}

	th {
		font-weight: 300;
		// color: #ececec;
		text-align: left;
		background: #2e2e2e;
	}

	th.highlight {
		font-weight: 400;
		color: #ffffff;
	}

}

.slider label {
	color: #ffffff;
}

.slider .bar {
	position: relative;
	width: 300px;
	// height: 40px;
}

.slider .bar-start {
	position: absolute;
	top: 10px;
	left: 0;
	border-top: 1px solid $linkColor;
	background: #1b1b1b;
	height: 2px;
}

.slider .bar-end {
	position: absolute;
	top: 10px;
	left: 40%;
	border-top: 1px solid #555555;
	background: #1b1b1b;
	width: 40%;
	height: 2px;
}

.slider button {
	position: absolute;
	left: 40%;
	top: 0;
	z-index: 2;
	width: 18px;
	height: 18px;
	padding: 0;
	transform: translateX(-50%);
	background: linear-gradient( #747474, #353535 );
	border-radius: 50%;
}