@mixin responsive-grid( $name, $units, $width ) {
	@media only screen and ( max-width: $width ) {
		@include grid( $name, $units );
	}
}

@mixin grid( $name, $units ) {

	@each $unit in $units {
		@include grid-unit( $name, $unit );
	}
	
	.#{$name}-hide { display: none; }
	.#{$name}-show { display: block; }
	.#{$name}-show-inline { display: inline; }
	.#{$name}-show-inline-block { display: inline-block; }
}

@mixin grid-unit( $name, $size ) {
	$percent: "%";
	$sizeLabel: nth( $size, 1 );
	$sizeValue: #{$size + $percent};
	
	@if( length( $size ) > 1 ) {
		$sizeValue: nth( $size, 2 );
	}
	
	.#{$name}-#{$sizeLabel} { width: $sizeValue; }
	.#{$name}-suffix-#{$sizeLabel} { margin-right: $sizeValue; }
	.#{$name}-prefix-#{$sizeLabel} { margin-left: $sizeValue; }
}

$gridName: grid !default;
$gridCellName: grid-cell !default;
$startRowName: start-row !default;

.#{$gridName} {
	position: relative;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	zoom: 1;
}

.#{$gridName}:before,
.#{$gridName}:after  {
    content: "";
    display: table;
    clear: both;
}

.#{$gridName} .#{$gridCellName} {
	float: left;
	position: relative;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}

.#{$gridName} .#{$gridCellName}-right {
	float: right;
	position: relative;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}

.#{$gridName} .#{$gridCellName}.#{$startRowName} {
    clear: both;
}
