
.product-item-content {
	flex-grow: 1;
	flex-basis: 0;
	align-items: stretch;
	font-weight: 300;
	color: #fcfcfc;
	background-color: #2e2e2e;
}

.product-item-content.current, .product-item-content.other {
	border: 1px solid #444444;
}

.product-item-content.recommended {
	border: 1px solid #d7418d;
}

.product-item-content .plan-details {
	padding: 25px;
	
}

.product-item-content .plan-label {
	padding: 5px;
	font-weight: 500;
}

.product-item-content.current .plan-label, .product-item-content.other .plan-label {
	background: #444444;
}

.product-item-content.recommended .plan-label {
	background: #d7418d;
}

.product-item-content h4 {
	font-size: 1.3em;
	color: #f949a1;
	text-transform: uppercase;
}

.product-item-content .price {
	font-size: 1.5em;
}

.product-item-content span {
	display: block;
}

.product-item-content .price .sale {
	color: #ff7ebe;
}

.product-item-content .price .strike {
	text-decoration: line-through;
	color: #989898;
}

.product-item-content .price-disclaimer {
	font-size: 0.8em;
}

.product-item-content ul {
	margin: 20px 0 0 0;
	padding: 20px 0;
	// font-size: 0.9em;
	color: #c3c3c3;
	border-top: 1px solid #444444;
	border-bottom: 1px solid #444444;
}

.product-item-content ul li {
	margin: 0 0 1px 0;
	list-style-type: none;
}

.product-item-content ul li.highlight {
	margin: 0 0 10px 0;
	// font-size: 1.1em;
	color: #fcfcfc;
	font-weight: bold;
}

.product-item-footer {
	padding: 20px 0 0;
	text-align: center;
}

.product-item-footer .subscribe-secondary {
	margin: 15px 0 0 0;
	display: block;
	font-size: 0.85em;
}

.product-item-content a {
	text-align: center;
}
