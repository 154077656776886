.tab-component-tabs {

	margin: 0;
	padding: 0;

	.button {
		margin: 0 1px 0 0;
		overflow: visible;
		font-weight: $fontBaseWeight;
		color: $textColor;
		background: linear-gradient( #595959, #292929 );
	}

	.button:hover {
		color: #FFFFFF;
		// background: linear-gradient( #222222, #222222 );
		background: linear-gradient( #595959, #292929 );
	}

	.selected {
		color: #FFFFFF;
		background: linear-gradient( #222222, #222222 );
		cursor: default;
	}

	.selected:hover {
		color: #FFFFFF;
		background: linear-gradient( #222222, #222222 );
	}

}

.tab-component-content-container {
	margin: 0;
	padding: 10px;
	background: #222222;
}