.modal {
	z-index: 1000;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.modal .background {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #1b1b1b;
	opacity: 0.95;
}

.modal .content-container {
	position: relative;
	z-index: 101;
	margin: 0 auto;
}

.modal .content-container .content {
	margin: $panelSpacing;
	padding: 18px;
	box-shadow: 0 0 4px 0px #101010;
	background: #222222;
}

.modal h3 {
	//padding: 18px 18px 0 18px;
	padding: 0;
	margin: 0 0 $panelSpacing 0;
	//background: linear-gradient( #666666, #333333 );
	//background: linear-gradient( #444444, #222222 );
}

.modal .alert {
	//padding: 18px;
	//background: #222222;

	.panel-cell:first-of-type {
		margin: 0;
		padding: 0;
	}
}

// .modal .dialog {
	//padding: 18px;
	//background: #222222;
	// padding: 18px 0 0;
// }

.modal .loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 102;
	//margin: 50% 0 0 50%;
}