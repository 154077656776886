.accordion {

	.accordion-header {
		display: block;
		padding: 5px 10px;
		width: 100%;
		text-align: left;
		color: $textColor;
		//background: #3f3f3f;
		background: linear-gradient( #444444, #333333 );
		// background: linear-gradient( #2c2c2c, #1f1f1f );
	}

	.accordion-list-content {
		display: none;
		background: #222222;
	}

	.accordion-list-content.selected {
		display: block;
	}

	.accordion-list-item {
		display: block;
		margin: 2px 0 0 0;
		padding: 5px 10px;
		width: 100%;
		text-align: left;
		color: $textColor;
		background: #2a2a2a;
	}

	.accordion-list-item:hover {
		color: #ffffff;
		background: #222222;
	}

	.accordion-list-item.selected {
		color: #ffffff; //$linkColor;
		background: #222222;
	}

}