.sign-in-container{
	position: fixed;
	top: 25%;
	left: 50%;
	width: 380px;
	height: 340px;
	transform: translate(-50%, -25%);
	padding: 40px;
	background: #1b1b1b;

	.logo {
		
		width: 300px;
		margin: 0 auto;

		span {
			display: block;
			cursor: default;
			text-align: center;
			font: 80px 'Alumni Sans', sans-serif;
			background: radial-gradient( at top, #ffffff, #494949 70% );
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		
		@keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		opacity: 0;
		animation: 0.75s ease-out forwards 0.5s 1 fadeIn;
	}

	.sign-in {
		position: relative;
	}

	input[type=text], input[type=password], input[type=email], .sign-in button, .error {
		margin: 0 0 15px 0;
	}
	
}
