.header {
	height: 28px;
	color: #eeeeee;
	background-color: #161616;
	vertical-align: middle;
}

.header .logo {
	display: inline-block;
	margin: -2px 0 -2px 10px;
	font: 25px 'Alumni Sans', sans-serif;
	background: linear-gradient( to bottom, #f1f1f1, #494949 80% );
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.header .controls {
	margin: 4px 0 0;
	font-size: 0.9em;
}

.header .drop-down {
	margin: 6px 0 0 0;
}

.header .profile-image {
	display: block;
	//position: absolute;
	//top: 6px;
	//right: 10px;
	margin: 4px 10px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	cursor: pointer;
}